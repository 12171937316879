//import React, { useState } from "react"
import React, { useState, useEffect } from 'react';
import { Link, graphql } from "gatsby"
import { useHistoryState } from "./useHistoryState";
import Layout from "../components/layout"


/* const TestFunc  = (props, query) => {

}
 */


const BlogIndex = props => {

  const { data } = props
  const allPosts = data.allMarkdownRemark.edges
  const emptyQuery = ""
  const [firstnamestate, setNameState] = useHistoryState("name_input", "");
  const [lastnamestate, setLastNameState] = useHistoryState("lastname_input", "");
  const [othernamesstate, setOtherNamesState] = useHistoryState("othernames_input", "");
  const [statusfreestate, setStatusFreeState] = useHistoryState("statusfree_checkbox", false);
  const [statusenslavedstate, setStatusEnslavedState] = useHistoryState("statusenslaved_checkbox", false);
  const [statusbothstate, setStatusBothState] = useHistoryState("statusboth_checkbox", false);

  const [state, setState] = useState({

    filteredData: [],
    query: emptyQuery,

  })

  
  const handleInputChange = event => {

   // console.log(event.target.value)
    let query = event.target.value
  //  setNameState (event.target.value)
  //  setLastNameState (event.target.value)

    switch(event.target.name) {
      case "firstname":
        setNameState (event.target.value)
        break;
      case "lastname":
        setLastNameState (event.target.value)
        break;

      case "othernames":
          setOtherNamesState (event.target.value)
          break; 
      case "statusfree":
            setStatusFreeState (event.target.checked)
            break;  
      case "statusenslaved":
            setStatusEnslavedState (event.target.checked)
            break;  
      case "statusboth":
            setStatusBothState (event.target.checked)
            break;  
      default:
        // code block
    } 




    const { data } = props
    const posts = data.allMarkdownRemark.edges || []
    const filteredData = posts.filter(post => {
    const { firstname, lastname, othernames, status, description, title, tags } = post.node.frontmatter
// status shoudl match one of the array members
      return (

        firstname.toLowerCase().includes(firstnamestate.toLowerCase()) &&
        lastname.toLowerCase().includes(lastnamestate.toLowerCase()) &&
        (othernames ? othernames : '').toLowerCase().includes(othernamesstate.toLowerCase()) &&
        ( checkedStatusBoxes.length===0 || (checkedStatusBoxes ? checkedStatusBoxes: '').includes (status.toLowerCase()) )   /*  ||
        (tags &&

          tags
            .join("")
            .toLowerCase()
            .includes(query.toLowerCase())) */
      ) 

    })


    setState({

      query,
      filteredData,
    })
  
  }

 

  const { filteredData, query } = state
  const hasSearchResults = filteredData && query !== emptyQuery
  const posts = hasSearchResults ? filteredData : allPosts
  

  let firstNameInput = React.createRef();
  let lastNameInput = React.createRef();
  let otherNamesInput = React.createRef();
  let statusFreeInput = React.createRef();
  let statusEnslavedInput = React.createRef();
  let statusBothInput = React.createRef();

  let checkedStatusBoxes=[];
  
  if (statusfreestate === true) {checkedStatusBoxes.push("free")}
  if (statusenslavedstate === true) {checkedStatusBoxes.push("enslaved")}
  if (statusbothstate === true) {checkedStatusBoxes.push("both")}

  let onOnclickHandler = (e) => { // reset all form fields
   // console.log(firstNameInput.current.value); 
  // firstNameInput.current.value="";
   setNameState ("");
   setLastNameState ("");
   setOtherNamesState ("");
   setStatusFreeState (false);
   setStatusEnslavedState (false);
   setStatusBothState (false);
   checkedStatusBoxes=[];


   const filteredData = data.allMarkdownRemark.edges
   
   setState({
    filteredData,
  })
  e.preventDefault();
  };

  useEffect(() => {
    // Run! Like go get some data from an API.
    //this.searchBox.focus(); 

    const restoreSearch = () => {
   
      //console.log (firstNameInput.current.value)
     
      // this.nameInput.focus(); 
    
     //const query = event.target.value
    //setNameState (event.target.value)


   const query2 =  firstNameInput.current.value
   
   //let query2 =  query

  //  if (query2 ===undefined) {
  //    query2=""
  //  }
   
   // const query2 = event.target.value;
      const { data } = props
       const posts = data.allMarkdownRemark.edges || []
    
       const filteredData = posts.filter(post => {
       const { firstname, lastname, othernames, status, description, title, tags } = post.node.frontmatter
     //  if (posts) { console.log("Array is empty!") }
   

         return (
          
          firstname.toLowerCase().includes(firstnamestate.toLowerCase()) &&
          lastname.toLowerCase().includes(lastnamestate.toLowerCase()) &&
          (othernames ? othernames : '').toLowerCase().includes(othernamesstate.toLowerCase()) &&
         ( checkedStatusBoxes.length===0 || (checkedStatusBoxes ? checkedStatusBoxes: '').includes (status.toLowerCase()) )
           /* ||
           (tags &&
   
             tags
               .join("")
               .toLowerCase()
               .includes(query2.toLowerCase())) */
         )
   
       }

      
       
       )
   
   
       setState({
   
         query2,
         filteredData,
       })
   
   
     }

    restoreSearch()
  }, [props, query ]);

  return (

    <>
<Layout>
      <h1 style={{ textAlign: `center` }}>Articles</h1>


       <div className="searchBox">

        <form>
         <label for="firstNameInput">First Name:</label>
        <input 
          ref={firstNameInput}
          id="firstNameInput"
          className="searchInput"
          type="text"
          name="firstname"
          value = {firstnamestate}
          aria-label="Search"
          placeholder="Type to filter articles..."

         onChange={handleInputChange}
    //     onFocus     ={handleInputChange} // this refreshes the form with input values that we get from history state
         />
   
        <label for="lastNameInput">Last Name:</label>
        <input 
          ref={lastNameInput}
          id="lastNameInput"
          className="searchInput"
          type="text"
          name="lastname"
          value = {lastnamestate}
          aria-label="Search"
          placeholder="Type to filter articles..."

         onChange={handleInputChange}
    //     onFocus     ={handleInputChange} // this refreshes the form with input values that we get from history state
         />
        <br/>

        <label for="otherNamesInput">Alternate names, nicknames, spellings etc.:</label>
        <input 
          ref={otherNamesInput}
          id="otherNamesInput"
          className="searchInput"
          type="text"
          name="othernames"
          value = {othernamesstate}
          aria-label="Search"
          placeholder="Type to filter articles..."

         onChange={handleInputChange}
    //     onFocus     ={handleInputChange} // this refreshes the form with input values that we get from history state
         />
        <br/>

    <p><b>Status: </b> <br/>
    <div className="searchInputIndented">
    <label for="statusFreeInput">Free</label>
        <input 
          ref={statusFreeInput}
          id="statusFreeInput"
          className="searchInput"
          type="checkbox"
          name="statusfree"
          checked = {statusfreestate}

         onChange={handleInputChange}
    //     onFocus     ={handleInputChange} // this refreshes the form with input values that we get from history state
         />
         <br/>
     <label for="statusEnslavedInput">Enslaved</label>
        <input 
          ref={statusEnslavedInput}
          id="statusEnslavedInput"
          className="searchInput"
          type="checkbox"
          name="statusenslaved"
          checked = {statusenslavedstate}

         onChange={handleInputChange}
    //     onFocus     ={handleInputChange} // this refreshes the form with input values that we get from history state
         />
         <br/>
  <label for="statusEnslavedInput">Both</label>
      <input 
          ref={statusBothInput}
          id="statusBothInput"
          className="searchInput"
          type="checkbox"
          name="statusboth"
          checked = {statusbothstate}

         onChange={handleInputChange}
    //     onFocus     ={handleInputChange} // this refreshes the form with input values that we get from history state
         />
      </div>
         </p>


      <button onClick={onOnclickHandler}>Clear form</button>
        </form>

  
      
      {posts.map(({ node }) => {

        const { excerpt } = node
     //   const { slug } = node.fields
        const { tags, title, date, description, slug } = node.frontmatter
        return (
          <article key={slug}>
            <header >
              <h2>
                <Link to={slug}>{title}</Link>
              </h2>
           
            </header>
            <section>
              <p
                dangerouslySetInnerHTML={{
                  __html: description || excerpt,
                }}

              />

            </section>
            <hr />
          </article>
   
        )  }
       )
     }
        </div>
      </Layout>
 
    </>

  )

}




export default BlogIndex


export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: ASC, fields: [frontmatter___slug] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            description
            firstname
            lastname
            othernames
            status
          }
        }
      }
    }
  }
`


